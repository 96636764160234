import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, EffectFade, Navigation, Pagination } from "swiper/modules";

import "./Banner.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Link, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { ThemeContext } from "../../Contexts/ThemeContext";
import lgBanner1 from "../../Images/clariss-Perfume-slider-desktop.png";
import lgBanner2 from "../../Images/Milk-Facewash-Slider.jpg";
import lgBanner3 from "../../Images/neem2.png";
import lgBanner4 from "../../Images/Clariss new year web cover pc.png";
import mbBanner1 from "../../Images/clariss-Perfume-slider-Mobile.png";
import mbBanner2 from "../../Images/Mobile-Milk-Facewash-Slider.jpg";
import mbBanner3 from "../../Images/neem-mb.png";
import mbBanner4 from "../../Images/Clariss new year web cover mobile.png";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);


const Banner = () => {
  const { searchText, setSearchText } = useContext(ThemeContext);

  const banner01 = window.innerWidth > 992 ? lgBanner1 : mbBanner1;
  const banner02 = window.innerWidth > 992 ? lgBanner2 : mbBanner2;
  const banner03 = window.innerWidth > 992 ? lgBanner3 : mbBanner3;
  const banner04 = window.innerWidth > 992 ? lgBanner4 : mbBanner4;

  const currentDate = dayjs().tz("Asia/Dhaka");

  const offerStartTime = dayjs.tz("2024-12-31 23:59:00", "Asia/Dhaka");
  const offerEndTime = dayjs.tz("2025-01-10 23:59:00", "Asia/Dhaka");

  const offerValidity =
    currentDate.isAfter(offerStartTime) && currentDate.isBefore(offerEndTime);


  const navigate = useNavigate();
  return (
      <div>
        <Swiper
          spaceBetween={30}
          effect={"fade"}
          navigation={window.innerWidth < 992 ? false : true}
          autoplay={{
            delay: 10000,
            disableOnInteraction: false,
          }}
          pagination={
            window.innerWidth < 700 ? false : { dynamicBullets: true }
          }
          modules={[Autoplay, EffectFade, Navigation, Pagination]}
          className="mySwiper h-auto"
        >
        {
          offerValidity && 
          <SwiperSlide>
            <Link to="/shop">
              <img
                className="w-full h-auto z-10"
                src={banner04}
                alt=""
              />
            </Link>
          </SwiperSlide>
        }
          
          <SwiperSlide>
            <Link to="/product/clariss-neem-face-wash">
              <img
                className="w-full h-auto z-10"
                src={banner03}
                alt=""
              />
            </Link>
          </SwiperSlide>
          <SwiperSlide>
            <Link to="/product-category/face wash">
              <img
                className="w-full h-auto z-10"
                src={banner02}
                alt=""
              />
            </Link>
          </SwiperSlide>
          {/* <SwiperSlide>
            <Link to="/product-category/fragrance">
              <img
                className="w-full h-auto z-10"
                src={banner01}
                alt=""
              />
            </Link>
          </SwiperSlide> */}
        </Swiper>
      </div>
  );
};

export default Banner;
